<script setup>
import moment from 'moment';
import { ref } from 'vue';

const loading = ref(false);

function start() {
  loading.value = true;
}

function finish() {
    loading.value = false;
}

defineExpose({ start, finish });

const todayDate = moment().format('DD-MM-YYYY')
</script>
<template>
    <template v-if="loading"><!--from-pink-500 to-indigo-500-->
        <div class="fixed top-0 left-0 w-full bg-gradient-to-r from-pink-500 to-indigo-500 text-white text-center p-2 z-40">
            <p class="font-bold">Chargement... veuillez patienter</p>
        </div>
        <div class="fixed left-5 bottom-5 z-50">
            <svg class="animate-bounce" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="margin:auto;background:transparent;display:block;" width="5rem" height="5rem" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
                <circle cx="50" cy="50" r="30" stroke="current" stroke-width="10" class="stroke-indigo-600" fill="none"></circle>
                <circle cx="50" cy="50" r="30" stroke="current" stroke-width="8" class="stroke-pink-500" stroke-linecap="round" fill="none">
                <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="1s" values="0 50 50;180 50 50;720 50 50" keyTimes="0;0.5;1"></animateTransform>
                <animate attributeName="stroke-dasharray" repeatCount="indefinite" dur="1s" values="18.84955592153876 169.64600329384882;94.2477796076938 94.24777960769377;18.84955592153876 169.64600329384882" keyTimes="0;0.5;1"></animate>
                </circle>
            </svg>
        </div>
    </template>
</template>
<style scoped>
.page-loading {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}
</style>